<template>
    <div>
        <KontainerDetailDialog
            :show="showDialog3"
            :action="action3"
            :item="dialogItem3"
            :containerDetail="containerDetail"
            :totalItems="dialogTotalItems"
            max_width="80%"
            v-on:onClose="onCloseEvent3"
            v-on:getContainerBoxDetail="getContainerBoxDetail"
        ></KontainerDetailDialog>
        <PrepaymentBindDialog
            :show="showDialog"
            :selected="selected"
            :prepayment_group_items="prepayment_group_items"
            v-on:onSave="onSaveEvent"
            v-on:onClose="onCloseEvent"
        ></PrepaymentBindDialog>
        <base-material-card
            icon="mdi-clipboard-text"
            :title="$t('container.container')"
            class="px-5 py-3"
        >
            <v-col class="text-right">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2 ml-4"
                    @click="addPrepaymentGroup"
                >
                <v-icon>mdi-plus</v-icon>
                {{ $t("container.pre-payment-dispatch") }}
                </v-btn>
            </v-col>
            <v-data-table
                v-model="selected"
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                :footer-props="footerProps"
                multi-sort
                show-select
            >
                <template v-slot:item.total_cubic_feet="{ item }">
                    {{ fixedFloat(item.total_cubic_feet, 2, 0) }}   
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-icon
                            large
                            class="mr-2"
                            v-on="on"
                            @click="viewDetail(item)"
                        >mdi-eye</v-icon>
                        </template>
                        <span>{{ $t("view") }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </base-material-card>        
    </div>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import KontainerDetailDialog from "../dialogs/KontainerDetailDialog"
import PrepaymentBindDialog from "../dialogs/PrepaymentBindDialog"
import { action_items,container_status } from "@/definition.js";
export default {
    props: {
        prepayment_group_items: [],
    },
    data: (vm) => ({
        url: "/container/container/",
        headers: [
            {
                sortable: true,
                text: vm.$i18n.t("ID"),
                value: "id",
            },
            {
                sortable: true,
                text: vm.$i18n.t("container.number"),
                value: "container_number",
            },
            {
                sortable: true,
                text: vm.$i18n.t("container.order_number"),
                value: "order_number",
            },
            {
                sortable: true,
                text: vm.$i18n.t("container.total_box_amount"),
                value: "total_box_amount",
            },
            {
                sortable: true,
                text: vm.$i18n.t("container.total_cubic_feet"),
                value: "total_cubic_feet",
            },
            {
                sortable: true,
                text: vm.$i18n.t("container.stuffing-date"),
                value: "stuffing_date",
            },
            {
                sortable: true,
                text: vm.$i18n.t("container.cutoff-date"),
                value: "cutoff_date",
            },
            // {
            //     sortable: false,
            //     text: vm.$i18n.t("export"),
            //     value: "export",
            // },
            {
                sortable: false,
                text: vm.$i18n.t("actions"),
                value: "actions",
            },
        ],
        action_items: action_items,
        container_status: container_status,
        selected: [],
        containerDetail: [],
        selectedContainerId: null,
        dialogTotalItems: 0,

        showDialog3: false,
        action3: action_items.view,
        dialogItem3: {},
        defaultItem3: {},
    }),
    mixins: [BaseTable],
    components: {
        KontainerDetailDialog,
        PrepaymentBindDialog,
    },
    methods: {
        viewDetail(item) {
            this.getApi("/container/container_box/", this.setContainerBoxDetail,false,{},{container: item.id});
            this.selectedContainerId = item.id
            // this.dialogItem = this._.cloneDeep(item);
            // this.action = action_items.view;
            this.showDialog3 = true;
        },
        setContainerBoxDetail(data) {
            this.dialogTotalItems = data.count
            this.containerDetail = data.results
        },
        onCloseEvent3(item) {
            this.showDialog3 = false;
        },
        getContainerBoxDetail(options){
            this.getApi("/container/container_box/", this.setContainerBoxDetail,false,{},{container: this.selectedContainerId},options);
        },

        addPrepaymentGroup() {
            if (this.selected.length > 0) {
                this.showDialog = true;
            } else {
                alert(this.$i18n.t("invoice.alert"));
            }
        },
        onSaveEvent(selected, mode, name, group, exchange_rate, start_period, end_period) {
            console.log(selected, mode, name, group);
            this.showDialog = false;
            if (selected.length > 0) {
                var ids = [];
                for (const [key, data] of Object.entries(selected)) {
                ids.push(data.id);
                }
                if (mode === 1) {
                const url = "/container/prepayment_group/" + group + "/append/";
                this.postApi({ containers: ids }, url);
                } else {
                const url = "/container/prepayment_group/";
                this.postApi({ 
                    containers: ids, 
                    name: name, 
                    exchange_rate: exchange_rate,
                    start_period: start_period,
                    end_period: end_period
                    }, url);
                }
                
            } else {
                alert(this.$i18n.t("invoice.alert"));
            }
        },
        postPostApi(action, responseData) {
            this.$emit('refresh')
        }
    },
    mounted() {
        this.getParams["prepayment_group__isnull"] = true
        this.getParams["has_prepayment_item"] = true;
        this.getParams["status__in"] = [
            this.container_status.enable,
            this.container_status.done,
            this.container_status.lock,
            this.container_status.close,
            this.container_status.invoice];
    },
}
</script>