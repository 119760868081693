<template>
    <v-container fluid>
        <PrepaymentDialog
            :show="showDialog"
            :action="action"
            :item="dialogItem"
            v-on:onClose="onCloseEvent"
            v-on:onSave="onSaveEvent"
        ></PrepaymentDialog>
        
        <base-material-card
            icon="mdi-clipboard-text"
            :title="$t('container.pre-payment-list')"
            class="px-5 py-3"
        >
            <v-data-table
                :headers="headers"
                :items="items"
                :options.sync="options"
                :server-items-length="totalItems"
                :loading="loading"
                :footer-props="footerProps"
                multi-sort
            >
                <template v-slot:item.export="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            class="mr-3"
                            @click="exportPrepaymentList(item)"
                        >
                            {{ $t("prepayment_list") }}
                        </v-btn>
                        </template>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-btn
                            small
                            class="mr-3"
                            @click="exportReceiveLite(item)"
                        >
                            {{ $t("prepayment_receipt_sheet") }}
                        </v-btn>
                        </template>
                    </v-tooltip>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                        <v-icon
                            large
                            class="mr-2"
                            v-on="on"
                            @click="viewItem(item)"
                        >mdi-eye</v-icon>
                        </template>
                        <span>{{ $t("view") }}</span>
                    </v-tooltip>
                </template>
            </v-data-table>
        </base-material-card>
        <PrepaymentContainer
            v-on:refresh="refresh"
            ref="containerList"
        ></PrepaymentContainer>
    </v-container>
</template>
<script>
import BaseTable from "./BaseTable.vue";
import PrepaymentContainer from "./PrepaymentContainerList"
import PrepaymentDialog from "../dialogs/PrepaymentDialog"
import { action_items,container_status } from "@/definition.js";
export default {
    data: (vm) => ({
        url: "/container/prepayment_group/",
        headers: [
            {
                sortable: true,
                text: vm.$i18n.t("ID"),
                value: "id",
            },
            {
                sortable: true,
                text: vm.$i18n.t("name"),
                value: "name",
            },
            {
                sortable: true,
                text: vm.$i18n.t("exchange_rate"),
                value: "exchange_rate",
            },
            {
                sortable: false,
                text: vm.$i18n.t("export"),
                value: "export",
            },
            {
                sortable: false,
                text: vm.$i18n.t("actions"),
                value: "actions",
            },
        ],
        items: [],
        options: {},
        totalItems: 0,
        loading: false,
        footerProps: {
            showFirstLastPage: true,
            showCurrentPage: true,
            firstIcon: "mdi-chevron-double-left",
            lastIcon: "mdi-chevron-double-right",
            prevIcon: "mdi-chevron-left",
            nextIcon: "mdi-chevron-right",
            itemsPerPageOptions: [20, 50, 100],
        },
        action_items: action_items,
        container_status: container_status,
    }),
    mixins: [BaseTable],
    components: {
        PrepaymentDialog,
        PrepaymentContainer,
    },
    methods: {
        exportPrepaymentList(item){
            console.log('item',item)
            const url = "/container/prepayment_group/" + item.id + "/getPrepaymentList/";
            this.getFileApi(url, "代付款月結單");
        },
        exportReceiveLite(item){
            console.log('item',item)
            const url = "/container/prepayment_group/" + item.id + "/getPrepaymentReceiptList/";
            this.getFileApi(url, "代付款簡易領貨單");
        },
        refresh(){
            this.getApi()
            this.$refs.containerList.getApi()
        },
    },
    mounted() {
        
    },

};
</script>