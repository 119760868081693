var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":_vm.max_width},on:{"click:outside":_vm.close,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close.apply(null, arguments)}},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.containerDetail,"options":_vm.options,"footer-props":_vm.footerProps,"server-items-length":_vm.totalItems},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.end_box",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_end_box(item))+" ")]}},{key:"item.commodity_name",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity.name ? data.commodity.name : ' '))])]})]}},{key:"item.commodity_number",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity.commodity_number))])]})]}},{key:"item.commodity_total_amount",fn:function(ref){
var item = ref.item;
return [_vm._l((item.relation),function(data){return [_c('div',[_vm._v(_vm._s(data.commodity_amount))])]})]}},{key:"item.supplier",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_supplier_name(item))+" ")]}},{key:"item.customer",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.get_customer_name(item))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.removeContainerBox(item)}}},on),[_vm._v("mdi-close")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("container-box.remove")))])])]}},{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{attrs:{"pagination":pagination,"options":options,"show-first-last-page":true,"show-current-page":true,"first-icon":"mdi-chevron-double-left","last-icon":"mdi-chevron-double-right","prev-icon":"mdi-chevron-left","next-icon":"mdi-chevron-right","items-per-page-options":[20, 50, 100, 200]},on:{"update:options":updateOptions}})]}}])})],1),_c('v-card-actions',[_c('v-btn',{on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('v-spacer'),(_vm.action != _vm.action_items.view)?_c('v-btn',{attrs:{"color":"primary","disabled":_vm.disableSave},on:{"click":_vm.saveSupplier}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }