<template>
    <v-dialog
        v-model="show"
        :max-width="max_width"
        @click:outside="close"
        @keydown.esc="close"
    >
        <v-card>
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
    data: (vm) => ({
        topic: vm.$i18n.t("container.prepayment"),
    }),
    mixins: [BaseDialog],
}
</script>