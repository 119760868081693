<template>
    <v-dialog
        v-model="show"
        :max-width="max_width"
        @click:outside="close"
        @keydown.esc="close"
    >
        <v-card height="45vh">
            <v-card-title>
                <span class="headline">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <!-- <v-text-field
                                v-model="group_name"
                                :label="$t('container.pre-payment-tip')"
                            ></v-text-field> -->
                            <DatePicker
                                v-model="group_period"
                                mode="date"
                            >
                                <template v-slot="{ inputValue, inputEvents }">
                                <v-text-field
                                    :value="inputValue"
                                    :label="$t('container.pre-payment-tip')"
                                    v-on="inputEvents"
                                >
                                    <template #label>
                                    <span class="red--text"><strong>* </strong></span>{{ $t('container.pre-payment-tip') }}
                                    </template>
                                </v-text-field>
                                </template>
                            </DatePicker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-text-field
                                v-model="exchange_rate"
                                :label="$t('container.exchange_rate')"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="close">{{ $t("cancel") }}</v-btn>
                <v-spacer></v-spacer>
                <v-btn
                color="primary"
                :disabled="allowSave"
                @click="save"
                >{{ $t("save") }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
import { DatePicker } from "v-calendar";
import BaseDialog from "./BaseDialog";
import moment from "moment";
export default {
    props: {
        selected: [],
        invoice_group_items: [],
    },
    data: (vm) => ({
        topic: vm.$i18n.t("container.prepayment"),
        mode: 2,
        exchange_rate: 4.5,
        group_period: null,
        prepayment_group: null,
    }),
    mixins: [BaseDialog],
    components: {
        DatePicker,
    },
    computed: {
        allowSave() {
        return false;
        },
    },
    methods: {
        save() {
            console.log(this.group_period)
            // let group_period = this.group_period
            let group_name = moment(new Date(this.group_period.getFullYear(), this.group_period.getMonth(), 1)).utc(true).format("YYYY-MM")
            let start_period = moment(new Date(this.group_period.getFullYear(), this.group_period.getMonth(), 1)).utc(true).format("YYYY-MM-DD")
            let end_period = moment(new Date(this.group_period.getFullYear(), this.group_period.getMonth() + 1, 0)).utc(true).format("YYYY-MM-DD")
            console.log(start_period,end_period)
            this.$emit(
                "onSave",
                this.selected,
                this.mode,
                group_name,
                null,
                this.exchange_rate,
                start_period,
                end_period
            );
        },
    },
}
</script>