<template>
  <v-dialog
    v-model="show"
    :max-width="max_width"
    @click:outside="close"
    @keydown.esc="close"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="containerDetail"
          :options.sync="options"
          :footer-props="footerProps"
          :server-items-length="totalItems"
        >
          <template v-slot:item.end_box="{ item }">
            {{ get_end_box(item) }}
          </template>
          <template v-slot:item.commodity_name="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity.name ? data.commodity.name : '&nbsp;' }}</div>
            </template>
          </template>
          <template v-slot:item.commodity_number="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity.commodity_number }}</div>
            </template>
          </template>
          <template v-slot:item.commodity_total_amount="{ item }">
            <template v-for="data in item.relation">
              <div>{{ data.commodity_amount }}</div>
            </template>
          </template>
          <template v-slot:item.supplier="{ item }">
            {{ get_supplier_name(item) }}
          </template>
          <template v-slot:item.customer="{ item }">
            {{ get_customer_name(item) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon
                  large
                  class="mr-2"
                  v-on="on"
                  @click="removeContainerBox(item)"
                >mdi-close</v-icon>
              </template>
              <span>{{ $t("container-box.remove") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:top="{pagination, options, updateOptions}">
            <v-data-footer 
              :pagination="pagination" 
              :options="options"
              @update:options="updateOptions"
              :show-first-last-page="true"
              :show-current-page="true"
              first-icon="mdi-chevron-double-left"
              last-icon="mdi-chevron-double-right"
              prev-icon="mdi-chevron-left"
              next-icon="mdi-chevron-right"
              :items-per-page-options="[20, 50, 100, 200]"
            />
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="close">{{ $t("cancel") }}</v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="action != action_items.view"
          color="primary"
          :disabled="disableSave"
          @click="saveSupplier"
        >{{ $t("save") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import BaseDialog from "./BaseDialog";
export default {
  props: {
    containerDetail: {
      type: Array,
      default: function () {
        return [];
      },
    },
    totalItems: {
      type: Number,
      default: 0
    },
  },
  computed: {},
  data: (vm) => ({
    headers: [
      {
        sortable: true,
        text: vm.$i18n.t("container.code"),
        value: "code",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.start_box"),
        value: "start_box",
      },
      {
        sortable: false,
        text: vm.$i18n.t("container.end_box"),
        value: "end_box",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.name"),
        value: "commodity_name",
      },
      {
        sortable: true,
        text: vm.$i18n.t("commodity.number"),
        value: "commodity_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.commodity_per_box"),
        value: "commodity_total_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.box_amount"),
        value: "box_amount",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cubic_feet"),
        value: "cubic_feet",
      },
      {
        sortable: true,
        text: vm.$i18n.t("supplier.supplier"),
        value: "supplier",
      },
      {
        sortable: true,
        text: vm.$i18n.t("customer.customer"),
        value: "customer",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.number"),
        value: "container.container_number",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.stuffing-date"),
        value: "container.stuffing_date",
      },
      {
        sortable: true,
        text: vm.$i18n.t("container.cutoff-date"),
        value: "container.cutoff_date",
      },
        {
          sortable: false,
          text: vm.$i18n.t("actions"),
          value: "actions",
        },
    ],
    options: {
      itemsPerPage: 200
    },
    footerProps: {
      showFirstLastPage: true,
      showCurrentPage: true,
      firstIcon: "mdi-chevron-double-left",
      lastIcon: "mdi-chevron-double-right",
      prevIcon: "mdi-chevron-left",
      nextIcon: "mdi-chevron-right",
      itemsPerPageOptions: [20, 50, 100, 200]
    },
  }),
  mixins: [BaseDialog],
  methods: {
    get_end_box(item) {
      return item.box_amount > 1 ? item.end_box : "";
    },
    get_supplier_name(item) {
      return this.getHanText(item.supplier, "name");
    },
    get_customer_name(item) {
      return this.getHanText(item.customer, "name");
    },
    removeContainerBox(item) {
      console.log('removeContainerBox',item)
      this.$emit('removeContainerBox',item)
    },
  },
  watch: {
    options: {
      handler() {
        console.log("watch options handler()");
        this.$emit('getContainerBoxDetail',this.options)
      }
    }
  },
};
</script>