var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PrepaymentDialog',{attrs:{"show":_vm.showDialog,"action":_vm.action,"item":_vm.dialogItem},on:{"onClose":_vm.onCloseEvent,"onSave":_vm.onSaveEvent}}),_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"icon":"mdi-clipboard-text","title":_vm.$t('container.pre-payment-list')}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.totalItems,"loading":_vm.loading,"footer-props":_vm.footerProps,"multi-sort":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.export",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.exportPrepaymentList(item)}}},[_vm._v(" "+_vm._s(_vm.$t("prepayment_list"))+" ")])]}}],null,true)}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mr-3",attrs:{"small":""},on:{"click":function($event){return _vm.exportReceiveLite(item)}}},[_vm._v(" "+_vm._s(_vm.$t("prepayment_receipt_sheet"))+" ")])]}}],null,true)})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"large":""},on:{"click":function($event){return _vm.viewItem(item)}}},on),[_vm._v("mdi-eye")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("view")))])])]}}])})],1),_c('PrepaymentContainer',{ref:"containerList",on:{"refresh":_vm.refresh}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }